<template>
    <b-modal id="updateQuantityModel" size="sm" title="Update Company's subscription quantity" @hidden="reset">
        <form @submit.prevent="updateSubscriptionQuantity" @reset.prevent="closeModal">
            <div class="row">
                <div class="col-md-12" v-if="company">
                    <div class="form-group">
                        <label>Method</label>
                        <b-form-select placeholder="Select Product" v-model="form.method"
                                       :options="['increment', 'decrement', 'specific']"/>
                    </div>

                    <div class="form-group">
                        <label>Quantity</label>
                        <b-form-input type="number" v-model="form.quantity" placeholder="Enter Quantity"/>
                    </div>
                </div>
                <div slot="modal-footer" class="col-md-12 text-center">
                    <button type="submit" class="btn btn-primary m-2">Update</button>
                    <button type="reset" class="btn btn-secondary m-2">Cancel</button>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
export default {
  name: "UpdateQuantity",
  data() {
    return {
      form: {
        method: "increment",
        quantity: 0
      },
      company: null
    };
  },
  mounted() {
    this.$root.$on("selected_company", company => {
      this.company = company;
    });
  },
  methods: {
    updateSubscriptionQuantity() {
      let data = {
        ...this.form,
        company_id: this.company.hash_id
      };
      this.$store.commit("toggle_loader", true);
      this.$store
        .dispatch("updateQuantity", data)
        .then(response => {
          this.$store.commit("toggle_loader", false);
          this.$parent.getDashboardData();
          if (!response.data.error) alert(response.data.error_msg);
          this.closeModal();
        })
        .catch(error => {
          this.$store.commit("toggle_loader", false);
          if (error.response.status === 422 && error.response.data.message)
            alert(
              error.response.data.message +
                "\n" +
                Object.values(error.response.data.errors)[0][0]
            );
        });
    },
    closeModal() {
      this.$root.$emit("bv::hide::modal", "updateQuantityModel");
    },
    reset() {
      this.form = {
        method: "increment",
        quantity: 0
      };
    }
  }
};
</script>

<style scoped>
.alignment {
  left: 5%;
  padding-top: 7px;
}
</style>
